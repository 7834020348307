/* eslint-disable react/prop-types */
import React from 'react';
import get from 'lodash/get';
import * as R from 'ramda';
import { Helmet } from 'react-helmet';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import * as moment from 'moment';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import { clearData } from '../../store/actions/availabilityActions';
import { clearDataAppointment, cancelAppointment } from '../../store/actions/appointmentActions';
import { clearDataTracking } from '../../store/actions/trackingActions';

import { getPageTitle } from '../../utils/SEO';

import { settings } from '../../sites';

const inlineStyles = theme => ({
  button: {
    width: '100%',
  },
  expansionPanel: {
    width: '100%',
  },
  h1: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: '15px',
    },
  },
  headline: {
    textTransform: 'uppercase',
  },
  bigger: {
    fontSize: 18,
  },
  biggerWrapper: {
    marginBottom: 20,
  },
  appointmentBox: {
    backgroundColor: 'rgb(242,247,250)',
    padding: theme.spacing(2.5, 2),
    marginBottom: 24,
    textAlign: 'left',
  },
  rowMargin: {
    marginBottom: 20,
  },
});

/**
 * Appointments
 * Use to display a successful fetch appointment result
 * @param {Object} classes    Classes from JSS inlineStyles
 */

const Appointments = ({
  classes,
  t,
  lang,
  history,
  // eslint-disable-next-line no-shadow
  cancelAppointment,
  localeLong,
  reservationDetails,
  appointmentType,
}) => {
  const {
    appointmentDate,
    startTimeShort,
    startTimeParsed,
    appointmentDuration,
    appointmentStore,
    appointmentAddress,
    appointmentUUID,
    opticianName,
  } = reservationDetails;

  const appointmentName = R.path(['fields', `nameIn${localeLong}`], appointmentType);
  const appointmentKey = R.path(['fields', 'urlFragment'], appointmentType);

  const appointmentTime = moment(startTimeParsed);
  const now = moment(new Date());

  const cancelPossible =
    appointmentKey === 'eye-test-and-eye-health-exam'
      ? moment.duration(appointmentTime.diff(now)).asHours() > 12
      : true;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{getPageTitle(t('views.appointmentDetails.myReservation'))}</title>
      </Helmet>
      <Grid container direction="row" spacing={0}>
        <Grid item xs={12}>
          <Typography variant="h1" className={classes.h1}>
            {t('views.appointmentLogin.ownReservation')}
          </Typography>
        </Grid>
      </Grid>

      <Grid container direction="row" spacing={0} className={classes.appointmentBox}>
        <Grid item xs={12} align="left">
          <Typography className={classes.headline} variant="h5">
            {appointmentName}
          </Typography>
        </Grid>

        <Grid item xs={12} align="left">
          <div className={classes.biggerWrapper}>
            <Typography className={classes.bigger} variant="body1">
              {appointmentDate}
            </Typography>
            <Typography className={classes.bigger} variant="body1">
              {t('dateTime.clock')} {startTimeShort}({t('views.appointmentDetails.duration')} {appointmentDuration}{' '}
              {t('views.appointmentDetails.minutes')})
            </Typography>
          </div>
          <Typography variant="body2">
            <strong>{appointmentStore}</strong>
          </Typography>
          <Typography variant="body2">{appointmentAddress}</Typography>
          {get(settings, 'showOpticians') && (
            <Typography className={classes.rowMargin} variant="body2">
              <strong>{opticianName}</strong>
            </Typography>
          )}

          <Typography variant="body2">{cancelPossible && t('views.appointmentDetails.instructions')}</Typography>
        </Grid>
        {cancelPossible && (
          <Grid item xs={12} align="right" sx={{ marginTop: 2 }}>
            <Button
              disableRipple
              color="secondary"
              variant="outlined"
              component={Box}
              onClick={() => cancelAppointment(appointmentUUID)}>
              <strong>{t('views.appointmentLogin.cancel')}</strong>
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} align="center">
        <Button
          disableRipple
          color="secondary"
          variant="contained"
          component={Box}
          onClick={() => history.push(`/${lang}/appointment/logout`)}>
          <strong>{t('views.appointmentLogin.logOut')}</strong>
        </Button>
      </Grid>
    </>
  );
};

const mapStateToProps = state => {
  const reservationDetails = R.path(['appointment', 'reservationDetails'], state);
  const appointmentGATID = R.path(['appointmentGATID'], reservationDetails);
  const subServices = R.path(['contentful', 'subServices'], state);

  const appointmentType = R.head(subServices.items.filter(item => item.fields.grandVisionApiId === appointmentGATID));

  return {
    reservationDetails,
    appointmentType,
    localeLong: R.path(['contentful', 'localeLong'], state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  cancelAppointment: values =>
    dispatch(cancelAppointment(values, R.path(['history'], ownProps), R.path(['lang'], ownProps))),
  clearData: () => dispatch(clearData()),
  clearDataAppointment: () => dispatch(clearDataAppointment()),
  clearDataTracking: () => dispatch(clearDataTracking()),
});

export default withStyles(inlineStyles)(
  withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Appointments)),
);
