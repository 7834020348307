import get from 'lodash/get';
import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { settings } from './sites';
import { getLanguageCodeArr } from './utils/localeUtils';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      // order and from where user language should be detected
      order: ['cookie', 'localStorage'],
    },
    fallbackLng: get(settings, ['locales', 0, 'languageCode']),
    initImmediate: false,
    supportedLngs: getLanguageCodeArr(),
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react!!
    },

    react: {
      useSuspense: false,
    },
  });

export default i18n;
