/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import get from 'lodash/get';
import * as R from 'ramda';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import * as moment from 'moment';
import 'moment/locale/et';
import 'moment/locale/fi';
import 'moment/locale/ru';
import 'moment/locale/sv';
import { connect } from 'react-redux';
import { change, Field, formValueSelector, reduxForm, submit, untouch } from 'redux-form';

import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';

import { isEqual } from 'lodash';
import Alert from '../subcomponents/Alert';
import AvailableTime from '../subcomponents/AvailableTime';
import BackButton from '../subcomponents/BackButton';
import SelectedServiceBox from '../subcomponents/SelectedServiceBox';

import { settings, siteId } from '../../sites';

import {
  clearAvailability,
  clearAvailableOpticians,
  clearFetchTimeStamp,
  clearMonthAvailability,
  getAvailability,
  getSpecialists,
  setAvailabilityStartDate,
} from '../../store/actions/availabilityActions';
import { clearUsedRedirection, saveUsedRedirection } from '../../store/actions/contentfulActions';
import {
  trackAvailableTimes,
  trackSaveUrlFragments,
  trackTimeFilterAfternoon,
  trackTimeFilterDay,
  trackTimeFilterEvening,
  trackTimeFilterMorning,
  trackUrlChange,
} from '../../store/actions/trackingActions';

import { returnField, validate } from '../../utils/FormHelpers';

import { siteIds } from '../../constants';
import { getDefaultGatCode, getDefaultLocaleLong, getLanguageGatCode } from '../../utils/localeUtils';
import { getPageTitle } from '../../utils/SEO';
import Calendar from '../subcomponents/Calendar';

const inlineStyles = theme => ({
  button: {
    width: '100%',
    textTransform: 'initial',
    fontSize: '14px',
    marginTop: 10,
    marginBottom: 10,
    [theme.breakpoints.up('md')]: {
      justifyContent: 'start',
      fontSize: '15px',
    },
  },
  expansionPanel: {
    width: '100%',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    margin: 0,
    padding: 0,
    '&:before': {
      opacity: '0',
    },
  },
  expansionPanelSummary: {
    padding: 0,
  },
  details: {
    padding: 0,
  },
  availableTimes: {
    [theme.breakpoints.up('md')]: {
      marginTop: 30,
    },
    [theme.breakpoints.up('lg')]: {
      padding: 0,
    },
  },
  field: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 20,
    },
  },
  form: {
    marginBottom: 30,
    [theme.breakpoints.up('md')]: {
      backgroundColor: 'rgb(242,247,250)',
      marginBottom: 20,
      paddingTop: 30,
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  buttonGroupButton: {
    color: theme.palette.primary.secondary,
    fontSize: 11,
    [theme.breakpoints.up('md')]: {
      fontSize: 14,
    },
  },
  buttonGroup: {
    marginBottom: 20,
    marginTop: 15,
  },
  h3: {
    color: theme.palette.primary.main,
    textAlign: 'left',
  },
  serviceBox: {
    [theme.breakpoints.down('md')]: {
      order: -1,
    },
  },
  chooseLocationAndTime: {
    paddingRight: 31,
  },
  body1: {
    textAlign: 'left',
    color: theme.palette.primary.main,
  },
  progressContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  circularProgress: {
    marginRight: '20px',
  },
  alert: {
    marginBottom: theme.spacing(4),
  },
});

const timeout = null;

/**
 * SelectService
 * Filtering view to find an instance of a service (e.g. 13.30 @ Aleksanterinkatu Helsinki)
 * @param {Object} classes             Classes from JSS inlineStyles
 * @param {function} handleSubmit      Submit handling function
 * @param {function} getAvailability   Get Availability function
 * @param {function} clearAvailability Clear Availability function
 * @param {object} availableTimes      All available times
 * @param {object} history             History object for redirecting
 * @param {Object} t                   Translations
 */

class SelectService extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    timeFilter: moment('07:00', 'HH:mm'),
    updateTimeStamp: Date(),
    stateTimeSlots: [],
    reservationDate: moment(),
  };

  componentDidMount() {
    const { remoteSubmit } = this.props;
    this.selectTimeOfDayFilter(moment('07:00', 'HH:mm'));
    remoteSubmit();
  }

  componentWillUnmount() {
    clearTimeout(timeout);
  }

  selectTimeOfDayFilter = time => {
    this.setState({
      timeFilter: time,
    });
  };

  // eslint-disable-next-line class-methods-use-this
  getOpticianNameById = (id, availableOpticians) => {
    if (!id || availableOpticians.length === 0) return '';

    const correctOptician = availableOpticians.filter(optician => optician.key === id.toString());

    return correctOptician.length > 0 ? R.path(['value'], correctOptician[0]) : '';
  };

  // eslint-disable-next-line class-methods-use-this
  getKeyForTimeSlot = item => `${item.key}${item.storeId}${item.appointmentType}`;

  render() {
    const {
      trackTimeFilterMorning,
      trackTimeFilterDay,
      trackTimeFilterAfternoon,
      trackTimeFilterEvening,
      trackAvailableTimes,
      classes,
      handleSubmit,
      getAvailability,
      getSpecialists,
      availableTimes,
      redirection,
      availabilityCallInProgress,
      specialistCallInProgress,
      clearFetchTimeStamp,
      fetchTimeStamp,
      fetchedMonthAvailabilities,
      stores,
      history,
      cities,
      selectedCity,
      selectedStore,
      selectedOptician,
      selectedServiceBox,
      storesGroupedByCities,
      opticiansAvailable,
      storesWhereAvailable,
      setAvailabilityStartDate,
      i18n,
      t,
      lang,
      localeLong,
      showOnlyStartTime,
    } = this.props;
    const { timeFilter, updateTimeStamp, reservationDate, stateTimeSlots } = this.state;

    let timeSlots = [];

    moment.locale(lang);

    if (lang === 'fi') {
      moment.updateLocale('fi', {
        weekdays: [
          'sunnuntaina',
          'maanantaina',
          'tiistaina',
          'keskiviikkona',
          'torstaina',
          'perjantaina',
          'lauantaina',
        ],
      });
    }

    const getLocale = () => i18n.language;

    let availableTimesFiltered = availableTimes;

    let filteredCities = cities;

    const defaultLanguageGatCode = getDefaultGatCode();
    const languageGatCode = getLanguageGatCode(lang);

    if (storesWhereAvailable && storesWhereAvailable.length > 0) {
      const citiesFilteredByStore = Object.values(storesGroupedByCities).filter(
        city =>
          city.filter(store =>
            storesWhereAvailable.includes(R.path(['attributes', 'name', defaultLanguageGatCode], store)),
          ).length > 0,
      );

      filteredCities = R.uniq(citiesFilteredByStore.map(city => R.path(['city'], city[0])));
    }
    // Clear time slots, and populate them only if there are available times
    timeSlots = [];
    if (availableTimes) {
      const match = availableTimes.filter(
        item => item.attributes.date === moment(reservationDate).format('YYYY-MM-DD'),
      );
      if (match.length > 0) {
        // Available times were found on selected day
        const timeSlotsPerStore = match.map(item => item.attributes.timeSlots);

        // Concatenate together
        let allStoresTimeSlots = [];
        allStoresTimeSlots = allStoresTimeSlots.concat(...timeSlotsPerStore);
        allStoresTimeSlots.forEach(item => {
          // check for duplicates, sometimes when user clicks fast on the day change, there might be duplicate entries
          const existingItem = timeSlots.find(slot => this.getKeyForTimeSlot(item) === this.getKeyForTimeSlot(slot));
          if (!existingItem) {
            timeSlots.push(item);
          }
        });
      }
    }

    const minutesOfDay = function (m) {
      return m.minutes() + m.hours() * 60;
    };

    // Display timeslots based on user defined startTime & store filters
    let timeSlotsFiltered = timeSlots.filter(item => {
      const startTimeParsed = moment(item.startTime, 'YYYY-MM-DDTHH:mm:ss');

      const isAfter = minutesOfDay(startTimeParsed) >= minutesOfDay(timeFilter);

      if (selectedStore) {
        const matchStore = JSON.parse(selectedStore).key === item.storeId;
        return isAfter && matchStore && item;
      }
      return isAfter && item;
    });

    // Filter calendar display if store is selected
    if (selectedStore) {
      availableTimesFiltered = availableTimesFiltered.filter(item => item.storeId === JSON.parse(selectedStore).key);
    }

    // Sort timeslots based on their startTime
    const sortByFirstItem = R.sortBy(R.prop('startTime'));
    timeSlotsFiltered = sortByFirstItem(timeSlotsFiltered);

    let storesWithinSelectedCity = [];

    if (selectedCity && Array.isArray(storesGroupedByCities[selectedCity])) {
      if (storesWhereAvailable && storesWhereAvailable.length > 0) {
        storesGroupedByCities[selectedCity].forEach(item => {
          if (storesWhereAvailable.includes(R.path(['attributes', 'name', defaultLanguageGatCode], item))) {
            storesWithinSelectedCity.push({
              key: R.path(['id'], item),
              value: R.path(['attributes', 'name', languageGatCode], item),
            });
          }
        });
      } else {
        storesWithinSelectedCity = storesGroupedByCities[selectedCity].map(item => ({
          key: R.path(['id'], item),
          value: R.path(['attributes', 'name', languageGatCode], item),
        }));
      }

      // Rearrange stores alphabetically
      storesWithinSelectedCity.sort((a, b) => a.value.localeCompare(b.value));
    }

    // Get opticians per store and map for selected field
    let opticiansMappedForSelectField = [];

    opticiansMappedForSelectField = opticiansAvailable.filter(
      (item, index, self) => index === self.findIndex(optician => optician.key === item.key),
    ); // Avoid duplicates

    if (selectedStore) {
      opticiansMappedForSelectField = opticiansAvailable.filter(item => item.storeId === JSON.parse(selectedStore).key);
    }

    opticiansMappedForSelectField.sort((a, b) => a.value.localeCompare(b.value));

    const pageTitle = [R.path(['headline'], selectedServiceBox)];

    if (selectedCity) {
      pageTitle.push(selectedCity);

      if (
        JSON.stringify(timeSlotsFiltered) !== JSON.stringify(stateTimeSlots) ||
        (fetchTimeStamp && fetchTimeStamp > updateTimeStamp)
      ) {
        trackAvailableTimes(timeSlotsFiltered);
        this.setState({ updateTimeStamp: fetchTimeStamp });
        this.setState({ stateTimeSlots: timeSlotsFiltered });
        clearFetchTimeStamp();
      }
    }

    if (selectedStore) {
      pageTitle.push(JSON.parse(selectedStore).value);
    }

    const requestAbortController = React.useRef < AbortController;

    const handleDaySelection = newDate => {
      this.setState({ reservationDate: newDate });
    };

    const handleMonthChange = month => {
      if (requestAbortController.current) {
        // make sure that you are aborting useless requests
        // because it is possible to switch between months pretty quickly
        requestAbortController.current.abort();
        return;
      }

      if (fetchedMonthAvailabilities.includes(month.format('MM-YYYY'))) {
        // Do no fetch availabilities again if they have been fetched already but save current viewed month.
        setAvailabilityStartDate(month.format('YYYY-MM-DD'));
        return;
      }

      const values = {
        city: selectedCity,
        person: selectedOptician,
        store: selectedStore,
        availabilityStartDate: month.format('YYYY-MM-DD'),
      };

      getAvailability(values);
    };

    let redirectionText = get(redirection, [`infoTextIn${localeLong}`]);

    if (redirectionText) {
      localStorage.setItem('alertText', redirectionText);
    } else {
      redirectionText = localStorage.getItem('alertText');
      setTimeout(() => {
        localStorage.removeItem('alertText');
      }, '3000');
    }

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{getPageTitle(pageTitle)}</title>
        </Helmet>
        <Grid container direction="row" spacing={0}>
          <Grid item xs={12} className={classes.chooseLocationAndTime}>
            <Typography variant="h1" className={classes.h1}>
              {t('views.selectService.chooseLocationAndTime')}
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={0}>
          {redirectionText && (
            <Grid item xs={12} className={classes.alert}>
              <Alert text={redirectionText} type="error" />
            </Grid>
          )}
        </Grid>
        <Grid container direction="row" columnSpacing={5} rowSpacing={2}>
          <Grid item xs={12} md={8}>
            <form className={classes.form} onSubmit={handleSubmit(getAvailability)}>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Field
                    key="city"
                    id="city"
                    name="city"
                    component={returnField('select')}
                    label={t('views.selectService.city')}
                    type="text"
                    selOpts={filteredCities}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6} className={classes.field}>
                  <Field
                    key="store"
                    id="store"
                    name="store"
                    component={returnField('select')}
                    label={t('views.selectService.store')}
                    placeholder={t('views.selectService.selectStore')}
                    type="text"
                    disabled={!selectedCity}
                    selOpts={storesWithinSelectedCity}
                    optionsAreObjects
                    fullWidth
                  />
                </Grid>

                {get(settings, 'showOpticians', true) && (
                  <Grid item xs={12} md={12}>
                    <Field
                      disabled={!selectedCity}
                      key="person"
                      id="person"
                      name="person"
                      component={returnField('select')}
                      label={t('views.selectService.specialist')}
                      type="text"
                      selOpts={opticiansMappedForSelectField}
                      fullWidth
                      placeholder={t('views.selectService.selectOptician')}
                      optionsAreObjects
                      dataFetchInProgress={specialistCallInProgress}
                      onOpen={() => {
                        if (selectedCity !== undefined && opticiansMappedForSelectField.length === 0) {
                          const values = {
                            city: selectedCity,
                            store: selectedStore,
                          };

                          getSpecialists(values);
                        }
                      }}
                    />
                  </Grid>
                )}

                {selectedCity && (
                  <Grid item xs={12} md={6} style={{ position: 'relative', display: 'table' }}>
                    <Calendar
                      key="reservationDate"
                      id="reservationDate"
                      name="reservationDate"
                      component={returnField('date')}
                      label={t('views.selectService.day')}
                      type="date"
                      lang={lang}
                      disabled={availabilityCallInProgress}
                      availableTimes={
                        availableTimesFiltered && Array.isArray(availableTimesFiltered) && availableTimesFiltered
                      }
                      onMonthChange={handleMonthChange}
                      onChange={handleDaySelection}
                      value={reservationDate}
                    />
                  </Grid>
                )}
              </Grid>
            </form>
            <Box className={classes.availableTimes}>
              <Typography variant="h3" gutterBottom className={classes.h3}>
                {t('views.selectService.freeSlots')} {moment(reservationDate).locale(lang).format('dddd D.M.YYYY')}
              </Typography>
              <ButtonGroup
                color="secondary"
                aria-label="outlined secondary button group"
                fullWidth
                className={classes.buttonGroup}>
                <Button
                  disableRipple
                  variant={moment('07:00', 'HH:mm').isSame(timeFilter) ? 'contained' : 'outlined'}
                  onClick={() => {
                    trackTimeFilterMorning();
                    this.selectTimeOfDayFilter(moment('07:00', 'HH:mm'));
                  }}
                  className={classes.buttonGroupButton}>
                  {t('views.selectService.morning')} (7:00-)
                </Button>
                <Button
                  disableRipple
                  variant={moment('11:00', 'HH:mm').isSame(timeFilter) ? 'contained' : 'outlined'}
                  onClick={() => {
                    trackTimeFilterDay();
                    this.selectTimeOfDayFilter(moment('11:00', 'HH:mm'));
                  }}
                  className={classes.buttonGroupButton}>
                  {t('views.selectService.day')} (11:00-)
                </Button>
                <Button
                  disableRipple
                  variant={moment('15:00', 'HH:mm').isSame(timeFilter) ? 'contained' : 'outlined'}
                  onClick={() => {
                    trackTimeFilterAfternoon();
                    this.selectTimeOfDayFilter(moment('15:00', 'HH:mm'));
                  }}
                  className={classes.buttonGroupButton}>
                  {t('views.selectService.afternoon')} (15:00-)
                </Button>
                <Button
                  disableRipple
                  variant={moment('18:00', 'HH:mm').isSame(timeFilter) ? 'contained' : 'outlined'}
                  onClick={() => {
                    trackTimeFilterEvening();
                    this.selectTimeOfDayFilter(moment('18:00', 'HH:mm'));
                  }}
                  className={classes.buttonGroupButton}>
                  {t('views.selectService.evening')} (18:00-)
                </Button>
              </ButtonGroup>
              <Divider />
            </Box>

            {!availabilityCallInProgress &&
              timeSlotsFiltered &&
              timeSlotsFiltered.length > 0 &&
              timeSlotsFiltered.map(item => (
                <AvailableTime
                  key={this.getKeyForTimeSlot(item)}
                  day={moment(reservationDate).format('dddd D.M.YYYY')}
                  storeId={item.storeId}
                  appointmentType={item.appointmentType}
                  stores={stores}
                  history={history}
                  content={item}
                  lang={lang}
                  showOnlyStartTime={showOnlyStartTime}
                  selectedOptician={selectedOptician ? JSON.parse(selectedOptician).key : item.opticians[0]}
                  selectedOpticianName={
                    selectedOptician
                      ? JSON.parse(selectedOptician).value
                      : this.getOpticianNameById(item.opticians[0], opticiansMappedForSelectField)
                  }
                />
              ))}
            {!availabilityCallInProgress && (!timeSlotsFiltered || timeSlotsFiltered.length < 1) && (
              <Typography variant="body1" gutterBottom style={{ marginTop: '20px' }}>
                {t('views.selectService.noFreeSlots')}
              </Typography>
            )}
            {availabilityCallInProgress && (
              <div className={classes.progressContainer}>
                <CircularProgress className={classes.circularProgress} />
                <Typography variant="body1" gutterBottom style={{ marginTop: '20px' }}>
                  Ladataan...
                </Typography>
              </div>
            )}
            <Box sx={{ marginTop: 5 }}>
              <BackButton to={`/${getLocale()}`} />
            </Box>
          </Grid>

          <Grid item xs={12} md={4} className={classes.serviceBox}>
            <SelectedServiceBox content={selectedServiceBox} />
          </Grid>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const selector = formValueSelector('selectService');

  const initialValues = {};

  // Initialize form with url params
  const cityParam = R.path(['match', 'params', 'city'], ownProps);
  const storeParam = R.path(['match', 'params', 'store'], ownProps);
  if (cityParam) {
    const selectedCity = R.path(['availability', 'storesGroupedByCitiesByUrlKey', cityParam], state);
    const firstStore = R.head(selectedCity);
    const cityName = R.path(['city'], firstStore);
    initialValues.city = cityName;
  }
  if (cityParam && storeParam) {
    const selectedCity = R.path(['availability', 'storesGroupedByCitiesByUrlKey', cityParam], state);
    const selectedStore = R.head(selectedCity.filter(item => item.url === storeParam));
    const storeKey = R.path(['id'], selectedStore);
    const storeName = R.path(['attributes', 'name', R.path(['contentful', 'locale'], state)], selectedStore);
    initialValues.store = JSON.stringify({ key: storeKey, value: storeName });
  }

  return {
    availableTimes: R.path(['availability', 'available_time_slots'], state),
    redirection: R.path(['contentful', 'redirection'], state),
    redirections: R.path(['contentful', 'redirections'], state),
    storesWhereAvailable: R.path(['availability', 'storesWhereAvailable'], state),
    availabilityCallInProgress: R.path(['availability', 'availabilityCallInProgress'], state),
    specialistCallInProgress: R.path(['availability', 'specialistCallInProgress'], state),
    fetchTimeStamp: R.path(['availability', 'fetchTimeStamp'], state),
    reservationDate: R.path(['form', 'selectService', 'values', 'reservationDate'], state),
    cities: R.path(['availability', 'cities'], state),
    stores: R.path(['availability', 'stores'], state),
    storesGroupedByCities: R.path(['availability', 'storesGroupedByCities'], state),
    opticiansAvailable: R.path(['availability', 'opticiansAvailable'], state),
    selectedCity: selector(state, 'city'),
    selectedStore: selector(state, 'store'),
    selectedOptician: selector(state, 'person'),
    selectedServiceBox: {
      headline: R.path(['availability', 'subService'], state),
      description: R.path(['availability', 'subServiceDescription'], state),
      price: R.path(['availability', 'price'], state),
    },
    initialValues,
    fetchedMonthAvailabilities: R.path(['availability', 'fetchedMonthAvailabilities'], state),
    localeLong: R.path(['contentful', 'localeLong'], state),
    showOnlyStartTime:
      (isEqual(R.path(['availability', 'subServiceUrlFragment'], state), 'optician-eye-examination') ||
        isEqual(R.path(['availability', 'subServiceUrlFragment'], state), 'optician-eye-test')) &&
      !isEqual(siteId, siteIds.InstrumentariumEE),
  };
};

const mapDispatchToProps = dispatch => ({
  getAvailability: values => dispatch(getAvailability(values)),
  getSpecialists: values => dispatch(getSpecialists(values)),
  clearAvailability: () => dispatch(clearAvailability()),
  clearUsedRedirection: () => dispatch(clearUsedRedirection()),
  saveUsedRedirection: redirect => dispatch(saveUsedRedirection(redirect)),
  clearMonthAvailability: () => dispatch(clearMonthAvailability()),
  clearAvailableOpticians: () => dispatch(clearAvailableOpticians()),
  setAvailabilityStartDate: values => dispatch(setAvailabilityStartDate(values)),
  remoteSubmit: () => dispatch(submit('selectService')),
  trackTimeFilterMorning: () => dispatch(trackTimeFilterMorning()),
  trackTimeFilterDay: () => dispatch(trackTimeFilterDay()),
  trackTimeFilterAfternoon: () => dispatch(trackTimeFilterAfternoon()),
  trackTimeFilterEvening: () => dispatch(trackTimeFilterEvening()),
  trackSaveUrlFragments: values => dispatch(trackSaveUrlFragments(values)),
  trackUrlChange: () => dispatch(trackUrlChange()),
  trackAvailableTimes: values => dispatch(trackAvailableTimes(values)),
  clearFetchTimeStamp: () => dispatch(clearFetchTimeStamp()),
});

const FormDecoratedComponent = reduxForm({
  form: 'selectService',
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: false,
  updateUnregisteredFields: true,
  onChange: (values, dispatch, props, previousValues) => {
    props.clearUsedRedirection();

    // User has changed city or store -> update url
    if (
      R.path(['city'], previousValues) !== R.path(['city'], values) ||
      R.path(['store'], previousValues) !== R.path(['store'], values)
    ) {
      const urlReplaceNordicChars = string => {
        return string
          .replace(/ä/g, 'a')
          .replace(/Ä/g, 'A')
          .replace(/ö/g, 'o')
          .replace(/Ö/g, 'O')
          .replace(/å/g, 'a')
          .replace(/Å/g, 'A')
          .replace(/ /g, '-')
          .replace(/,/g, '-');
      };

      let cityandstore = '';
      const storeParsed = R.path(['store'], values) ? JSON.parse(R.path(['store'], values)).value : null;
      const citySlugified = R.path(['city'], values) && urlReplaceNordicChars(R.path(['city'], values)).toLowerCase();
      let storeSlugified = storeParsed && urlReplaceNordicChars(storeParsed).toLowerCase();
      let redirect = false;
      const defaultLocaleLong = getDefaultLocaleLong();

      if (storeParsed) {
        const { redirections } = props;

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < redirections.length; i++) {
          if (
            get(
              redirections,
              [i, `fromIn${props.localeLong}`],
              get(redirections, [i, `fromIn${defaultLocaleLong}`]),
            ) === storeSlugified
          ) {
            storeSlugified = get(
              redirections,
              [i, `toIn${props.localeLong}`],
              get(redirections, [i, `toIn${defaultLocaleLong}`]),
            );
            props.saveUsedRedirection(redirections[i]);
            redirect = true;
            break;
          }
        }
      }

      if (R.path(['city'], values)) {
        cityandstore = storeParsed ? `/${citySlugified}/${storeSlugified}` : `/${citySlugified}`;
      } else {
        cityandstore = '';
      }

      // Alter url based on user selections
      // eslint-disable-next-line no-unused-expressions
      window && window.history.replaceState(null, '', `/${props.lang}/${props.match.params.slug}${cityandstore}`);
      props.trackSaveUrlFragments({
        service: props.match.params.slug ? props.match.params.slug : '',
        city: citySlugified || '',
        store: storeSlugified || '',
      });
      props.trackUrlChange();
      props.clearMonthAvailability();

      if (redirect) {
        window.location.reload();
      }
    }

    if (R.path(['person'], previousValues) !== R.path(['person'], values)) {
      props.clearMonthAvailability();
    }

    // Clear selected store on city change or if city is not defined.
    if (R.path(['city'], previousValues) !== R.path(['city'], values) || !R.path(['city'], values)) {
      props.clearAvailableOpticians();
      dispatch(change('selectService', 'store', ''));
      dispatch(untouch('selectService', 'store'));
    }

    // Clear selected person on store change or if store is not defined.
    if (R.path(['store'], previousValues) !== R.path(['store'], values)) {
      dispatch(change('selectService', 'person', ''));
      dispatch(untouch('selectService', 'person'));
    }

    // City has remained the same as in the previous values object
    // There is no IMMEDIATE need to fetch availability again unless some conditions are met
    /* Also we would want to fetch the availability to reflect
    the current actual state if the browsing has lasted long without any data update */

    if (R.path(['city'], previousValues) === R.path(['city'], values)) {
      // Did the person change? And did it change to an actual person? If yes, fire a specialist call.
      if (R.path(['person'], previousValues) !== R.path(['person'], values) && R.path(['person'], values)) {
        props.clearAvailability();
        props.getAvailability(values);
      }

      // Did the person change but not to an actual person?
      if (R.path(['person'], previousValues) !== R.path(['person'], values) && !R.path(['person'], values)) {
        props.clearAvailability();
        props.getAvailability(values);
      }

      // Did the store change? We then need to get the opticians for this store.
      if (R.path(['store'], previousValues) !== R.path(['store'], values)) {
        props.clearAvailability();
        props.getAvailability(values);
      }
    } else {
      // Clear availability on city change
      props.clearAvailability();

      // Clear selected store on city change
      dispatch(change('selectService', 'store', ''));
      dispatch(change('selectService', 'person', ''));
      dispatch(untouch('selectService', 'store'));
      dispatch(untouch('selectService', 'person'));

      props.getAvailability(values);
    }
  },
  onSubmit: (values, dispatch, props) => {
    props.getAvailability(values);
  },
})(SelectService);

export default withStyles(inlineStyles)(
  withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(FormDecoratedComponent)),
);
