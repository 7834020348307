import * as R from 'ramda';
import sphereKnn from 'sphere-knn';
import * as ipstackApi from '../../api/ipstackApi';
import { getAllStores } from './availabilityActions';

export const GET_GEOLOCATION = 'GET_GEOLOCATION';
export const GET_GEOLOCATION_FAILURE = 'GET_GEOLOCATION_FAILURE';
export const GET_CLOSESTSHOPTOCUSTOMER = 'GET_CLOSESTSHOPTOCUSTOMER';

// AFTER TESTING GEOLOCATION FETCH NEEDS TO BE DONE IN THE CLOUD TO KEEP ACCESS KEY SECRET

/**
 * Get user geolocation from Ipstack API
 */
export const getUserGeolocation = () => dispatch =>
  ipstackApi
    .getUserGeolocation()
    .then(results =>
      Promise.all([
        dispatch({
          type: GET_GEOLOCATION,
          results,
        }),
      ]),
    )
    .catch(error =>
      Promise.all([
        dispatch({
          type: `${GET_GEOLOCATION}_FAILURE`,
          error,
        }),
      ]),
    );

export const getGeolocationAndAllStores = () => dispatch => {
  // Geolocation fetch is disabled for now.

  return Promise.all([dispatch(getAllStores())]);
};

export const getClosestShopToCustomer = () => (dispatch, getState) => {
  dispatch(getGeolocationAndAllStores())
    .then(() => {
      const storesLocations = R.path(['availability', 'storesLocations'], getState());
      const userLocation = R.path(['geolocation', 'geolocation'], getState());

      // Determine closest store coordinates to user coordinates
      const lookup = sphereKnn(storesLocations);
      const closestStore = lookup(R.path(['latitude'], userLocation), R.path(['longitude'], userLocation), 1);

      Promise.all([
        dispatch({
          type: GET_CLOSESTSHOPTOCUSTOMER,
          closestStore,
        }),
      ]);
    })
    .catch(error =>
      Promise.all([
        dispatch({
          type: `${GET_CLOSESTSHOPTOCUSTOMER}_FAILURE`,
          error,
        }),
      ]),
    );
};
