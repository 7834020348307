/* eslint-disable react/prop-types */
import React from 'react';
import * as R from 'ramda';
import { NavLink, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import get from 'lodash/get';

import { withStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Icon from '@mui/material/Icon';
import { withTranslation } from 'react-i18next';
import Logo from '../subcomponents/Logo';
import { saveLocale } from '../../store/actions/contentfulActions';
import { settings } from '../../sites';

const styles = theme => ({
  appBar: {},
  menuButton: {
    borderRadius: 1,
    height: 40,
    minWidth: 0,
    padding: 0,
    width: 44,
  },
  menuItem: {
    padding: 0,
    '& > a': {
      paddingLeft: 15,
      paddingRight: 15,
    },
  },
  navButton: {
    color: '#000',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  loginButton: {
    marginBottom: '10px',
    marginLeft: '20px',
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
    },
  },
  gridContainer: {
    textAlign: 'right',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
});

/**
 * Navigation
 * Use to display the navigation header
 * Contains logo and language menu or hamburger menu of the same contents on mobile
 */

const Navigation = ({ classes, i18n, history, logoImage, t }) => {
  const getLocale = () => i18n.language;

  const changeLanguage = lng => {
    history.push(`/${lng}`);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const underlineLink = lang => (getLocale() === lang ? { textDecoration: 'underline' } : {});

  const locales = get(settings, 'locales');

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar>
        <Grid
          container
          spacing={0}
          className={classes.gridContainer}
          justifyContent="space-between"
          alignItems="center">
          <Grid item xs={6} style={{ textAlign: 'left' }}>
            <NavLink to={`/${getLocale()}`} aria-label={t('views.navigation.toHome')}>
              <Logo logoImage={logoImage} />
            </NavLink>
          </Grid>
          <Grid item xs={6} alignSelf="flex-end">
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              {locales.map(locale => {
                const languageCode = get(locale, 'languageCode');
                return (
                  <IconButton
                    key={languageCode}
                    className={classes.navButton}
                    disableRipple
                    onClick={() => changeLanguage(languageCode)}
                    style={underlineLink(languageCode)}>
                    <Typography variant="body1">{t(`views.navigation.${languageCode}`)}</Typography>
                  </IconButton>
                );
              })}
              {get(settings, 'showMyAppointments') && (
                <Button
                  disableRipple
                  className={classes.loginButton}
                  variant="outlined"
                  component={Link}
                  to={`/${getLocale()}/login`}>
                  {t('views.appointmentLogin.ownReservation')}
                </Button>
              )}
            </Box>

            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
              <Button
                disableRipple
                className={classes.menuButton}
                variant="outlined"
                aria-label={t('views.navigation.menu')}
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}>
                <Icon fontSize="large">menu</Icon>
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}>
                {get(settings, 'showMyAppointments') && (
                  <MenuItem className={classes.menuItem}>
                    <IconButton disableRipple component={Link} to={`/${getLocale()}/login`}>
                      <Typography variant="body1">{t('views.appointmentLogin.ownReservation')}</Typography>
                    </IconButton>
                  </MenuItem>
                )}

                {locales.map(locale => {
                  const languageCode = get(locale, 'languageCode');
                  return (
                    <MenuItem className={classes.menuItem} key={languageCode}>
                      <IconButton
                        component={Link}
                        onClick={() => changeLanguage(languageCode)}
                        to={`/${languageCode}`}
                        style={underlineLink(languageCode)}>
                        <Typography variant="body1">{t(`views.navigation.${languageCode}`)}</Typography>
                      </IconButton>
                    </MenuItem>
                  );
                })}
              </Menu>
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

const mapStateToProps = state => ({
  logoImage: R.path(['contentful', 'layout', 'logo', 'fields', 'file', 'url'], state),
});

const mapDispatchToProps = dispatch => ({
  saveLocale: locale => dispatch(saveLocale(locale)),
});

export default withStyles(styles)(
  withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Navigation)),
);
