const settings = {
  locales: [
    // First locale is treated as default
    {
      languageCode: 'et',
      localeLong: 'Estonian',
      gatCode: 'et_EE',
    },
    {
      languageCode: 'en',
      localeLong: 'English',
      gatCode: 'en',
    },
  ],
  showOpticians: false,
  showMyAppointments: false,
  phone: {
    countryCode: '+372',
    numberOfDigits: 7,
  },
};

export default settings;
