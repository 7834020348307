import { isEqual } from 'lodash';

import { siteIds } from '../../../constants';
import { siteId } from '../../../sites';

import CommonCookieConsent from './common';

const CookieConsent = () => {
  if (isEqual(siteId, siteIds.InstrumentariumEE)) {
    return CommonCookieConsent;
  }
  return null;
};

export default CookieConsent;
