/* eslint-disable no-case-declarations */
import * as R from 'ramda';
import * as moment from 'moment';
import i18n from 'i18next';

import {
  GET_APPOINTMENT,
  ADD_APPOINTMENT_STATUS,
  GET_APPOINTMENT_LOGIN,
  GET_APPOINTMENT_LOGIN_EXCEPTION,
  GET_APPOINTMENT_LOGIN_FAILURE,
  CANCEL_APPOINTMENT,
  CLEAR_DATA_APPOINTMENT,
  CREATE_CUSTOMER_FAILURE,
  CREATE_APPOINTMENT_FAILURE,
  GET_APPOINTMENT_FAILURE,
} from '../actions/appointmentActions';
import { getLanguageGatCode } from '../../utils/localeUtils';

const initialState = {
  bookedAppointment: {},
  reservationDetails: {},
  status: '',
  serverFailure: false,
};

// eslint-disable-next-line default-param-last
export default function appointmentReducer(state = initialState, action) {
  const lang = i18n.language;
  const langGatCode = getLanguageGatCode(lang);

  switch (action.type) {
    case GET_APPOINTMENT:
      return {
        ...state,
        bookedAppointment: R.path(['results', 'data'], action),
        status: R.path(['results', 'data', 'attributes', 'status'], action),
        serverFailure: false,
      };
    case GET_APPOINTMENT_FAILURE:
      return {
        ...state,
        serverFailure: true,
      };
    case CREATE_CUSTOMER_FAILURE:
      return {
        ...state,
        serverFailure: true,
      };
    case CREATE_APPOINTMENT_FAILURE:
      return {
        ...state,
        serverFailure: true,
      };
    case GET_APPOINTMENT_LOGIN:
      const reservation = R.path(['results', 'reservation'], action);
      const store = R.path(['results', 'store'], action);
      const appointmentType = R.path(['results', 'appointmentType'], action);
      const optician = R.path(['results', 'optician'], action);

      moment.locale(lang);

      const startTimeParsed = moment(R.path(['attributes', 'startTime'], reservation), 'YYYY-MM-DDTHH:mm');
      const endTimeParsed = moment(R.path(['attributes', 'appointmentEndTime'], reservation), 'YYYY-MM-DDTHH:mm');

      const appointmentUUID = R.path(['id'], reservation);
      const appointmentGATID = R.path(['id'], appointmentType);
      const appointmentDate = startTimeParsed.format('dddd D.M.YYYY');
      const startTimeShort = startTimeParsed.format('HH:mm');
      const appointmentDuration = moment.duration(endTimeParsed.diff(startTimeParsed)).asMinutes();
      const appointmentStore = `${R.path(['attributes', 'name', langGatCode], store)}`;
      const appointmentAddress = `${R.path(['attributes', 'address', 'line1'], store)}, ${R.path(
        ['attributes', 'address', 'city'],
        store,
      )}`;

      const opticianName = `${R.path(['attributes', 'firstName'], optician)} ${R.path(
        ['attributes', 'lastName'],
        optician,
      )}, ${R.path(['attributes', 'title'], optician)}`;

      const reservationDetails = {
        appointmentDate,
        startTimeShort,
        startTimeParsed,
        appointmentDuration,
        appointmentStore,
        appointmentAddress,
        appointmentGATID,
        appointmentUUID,
        opticianName,
        status: 'Success',
      };

      return {
        ...state,
        reservationDetails,
        serverFailure: false,
      };
    case GET_APPOINTMENT_LOGIN_EXCEPTION:
      return {
        ...state,
        reservationDetails: { status: 'Exception' },
        serverFailure: false,
      };
    case GET_APPOINTMENT_LOGIN_FAILURE:
      return {
        ...state,
        reservationDetails: { status: 'Failure' },
        serverFailure: true,
      };
    case ADD_APPOINTMENT_STATUS:
      return {
        ...state,
        status: R.path(['status'], action),
      };
    case CANCEL_APPOINTMENT:
      return {
        ...state,
        reservationDetails: { status: 'Cancelled' },
      };
    case CLEAR_DATA_APPOINTMENT:
      return initialState;
    default:
      return state;
  }
}
