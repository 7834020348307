/* eslint-disable react/prop-types */
import React from 'react';
import * as R from 'ramda';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Icon from '@mui/material/Icon';
import Box from '@mui/material/Box';

import { connect } from 'react-redux';

import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import { trackSelectService } from '../../store/actions/trackingActions';

import SubService from './SubService';

const inlineStyles = theme => ({
  button: {
    width: '100%',
  },
  expansionPanelWrapper: {
    borderRadius: '4px',
    border: '1px solid rgb(210, 216, 219)',
    width: '100%',
    marginBottom: '20px',
  },
  expansionPanel: {
    boxShadow: 'none',
  },
  serviceHeadline: {
    color: theme.palette.primary.main,
    textAlign: 'left',
  },
});

/**
 * Service
 * Use to add a main service such as "Silmälääkäri" which can then hold SubServices
 * Works as an expansion panel
 * @param {Object} classes       Classes from JSS inlineStyles
 * @param {String} headline      Headline text
 * @param {String} caption       Caption text
 * @param {Array} subServices    Subservices as an array of objects
 * @param {Object} history       History object for redirecting
 */

// eslint-disable-next-line no-shadow
const Service = ({ classes, headline, caption, subServices, history, lang, id, trackSelectService, link, t }) => {
  const handleChange = () => {
    document.getElementById(id).click();
  };

  if (subServices.length === 0 && link) {
    return (
      <div className={classes.expansionPanelWrapper}>
        <Accordion className={classes.expansionPanel} onChange={handleChange}>
          <AccordionSummary className={classes.expansionPanelSummary}>
            <Grid container direction="column" spacing={0}>
              <Grid item>
                <Typography variant="h2" className={classes.serviceHeadline} gutterBottom>
                  {headline}
                </Typography>
              </Grid>
              <Grid item>
                <Typography align="left" variant="body1" color="secondary">
                  {caption}
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
        </Accordion>
        <a
          id={id}
          href={link}
          target="_blank"
          aria-label={t('views.availableTime.choose')}
          title={headline}
          rel="noopener noreferrer">
          <Box sx={{ display: 'none' }}>{headline}</Box>
        </a>
      </div>
    );
  }
  return (
    <div className={classes.expansionPanelWrapper}>
      <Accordion
        className={classes.expansionPanel}
        onChange={(event, expanded) => {
          if (expanded) {
            trackSelectService(id);
          }
        }}>
        <AccordionSummary
          className={classes.expansionPanelSummary}
          expandIcon={
            subServices.length > 0 && (
              <Icon color="primary" fontSize="large">
                arrow_drop_down
              </Icon>
            )
          }>
          <Grid container direction="column" spacing={0}>
            <Grid item>
              <Typography variant="h2" className={classes.serviceHeadline} gutterBottom>
                {headline}
              </Typography>
            </Grid>
            <Grid item>
              <Typography align="left" variant="body1" color="secondary">
                {caption}
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        {subServices.length > 0 && (
          <AccordionDetails className={classes.details}>
            <Grid container item alignItems="center" direction="column" spacing={2}>
              {subServices.map(item => (
                <SubService
                  lang={lang}
                  content={item}
                  history={history}
                  service={headline}
                  key={R.path(['headline'], item)}
                />
              ))}
            </Grid>
          </AccordionDetails>
        )}
      </Accordion>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  trackSelectService: id => dispatch(trackSelectService(id)),
});

export default withStyles(inlineStyles)(withTranslation('translations')(connect(null, mapDispatchToProps)(Service)));
