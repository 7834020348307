/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, isNil, isString } from 'lodash';
import { Divider, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Link from './Link';

const styles = theme => ({
  root: {
    ...theme.typography.body1,
    color: theme.palette.text.primary,
  },
  paragraph: {
    fontSize: 'inherit',
    fontWeight: 'inherit',
    marginBottom: '1rem',
    whiteSpace: 'pre-line',
  },
  olList: {
    fontSize: 'inherit',
    fontWeight: 'inherit',
    textAlign: 'left',
  },
  ulList: {
    listStyle: 'square',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    textAlign: 'left',
  },
  heading1: {
    marginBottom: '1rem',
  },
  heading2: {
    marginBottom: '1rem',
  },
  heading3: {
    marginBottom: '1rem',
  },
  heading4: {
    marginBottom: '1rem',
  },
  heading5: {
    marginBottom: '1rem',
  },
  heading6: {
    marginBottom: '1rem',
  },
  hyperlink: {
    cursor: 'pointer',
    fontSize: 'inherit',
    fontWeight: 'inherit',
  },
  bold: {
    fontSize: 'inherit',
    fontWeight: theme.typography.fontWeightBold,
  },
  italic: {
    fontSize: 'inherit',
    fontWeight: 'inherit',
    fontStyle: 'italic',
  },
  underline: {
    fontSize: 'inherit',
    fontWeight: 'inherit',
    textDecoration: 'underline',
  },
  hr: {
    margin: '1rem 0',
  },
});

function RichText({ classes, className, textData }) {
  const options = useMemo(() => {
    return {
      renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
          return <Typography className={classes.paragraph}>{children}</Typography>;
        },
        [BLOCKS.HEADING_1]: (node, children) => (
          <Typography variant="h1" className={classes.heading1}>
            {children}
          </Typography>
        ),
        [BLOCKS.HEADING_2]: (node, children) => (
          <Typography variant="h2" className={classes.heading2}>
            {children}
          </Typography>
        ),
        [BLOCKS.HEADING_3]: (node, children) => (
          <Typography variant="h3" className={classes.heading3}>
            {children}
          </Typography>
        ),
        [BLOCKS.HEADING_4]: (node, children) => (
          <Typography variant="h4" className={classes.heading4}>
            {children}
          </Typography>
        ),
        [BLOCKS.HEADING_5]: (node, children) => (
          <Typography variant="h5" className={classes.heading5}>
            {children}
          </Typography>
        ),
        [BLOCKS.HEADING_6]: (node, children) => (
          <Typography variant="h6" className={classes.heading6}>
            {children}
          </Typography>
        ),
        [BLOCKS.HR]: () => <Divider className={classes.hr} />,
        [BLOCKS.LIST_ITEM]: (node, children) => (
          <Typography component="li" className={classes.listItem}>
            {children}
          </Typography>
        ),
        [BLOCKS.OL_LIST]: (node, children) => (
          <Typography component="ol" className={classes.olList}>
            {children}
          </Typography>
        ),
        [BLOCKS.UL_LIST]: (node, children) => (
          <Typography component="ul" className={classes.ulList}>
            {children}
          </Typography>
        ),
        [BLOCKS.QUOTE]: (node, children) => (
          <Typography component="blockquote" className={classes.quote}>
            {children}
          </Typography>
        ),
        [INLINES.HYPERLINK]: (node, children) => {
          return (
            <Link to={get(node, 'data.uri')} target="_blank" className={classes.hyperlink}>
              {children}
            </Link>
          );
        },
        [INLINES.ENTRY_HYPERLINK]: (node, children) => {
          const entryData = get(node, 'data.target');
          const fullPath = get(entryData, 'fullPath');

          return (
            <Link to={fullPath} className={classes.hyperlink}>
              {children}
            </Link>
          );
        },
        [INLINES.ASSET_HYPERLINK]: (node, children) => {
          const entryData = get(node, 'data.target');
          const fileUrl = get(entryData, 'file.url');

          return (
            <Link to={fileUrl} target="_blank" className={classes.hyperlink}>
              {children}
            </Link>
          );
        },
      },
      renderMark: {
        [MARKS.BOLD]: text => (
          <Typography component="span" className={classes.bold}>
            {text}
          </Typography>
        ),
        [MARKS.CODE]: text => (
          <Typography component="span" className={classes.code}>
            {text}
          </Typography>
        ),
        [MARKS.ITALIC]: text => (
          <Typography component="span" className={classes.italic}>
            {text}
          </Typography>
        ),
        [MARKS.UNDERLINE]: text => (
          <Typography component="span" className={classes.underline}>
            {text}
          </Typography>
        ),
      },
    };
  }, [classes]);

  if (isString(textData)) {
    return (
      <div className={clsx(classes.root, className)}>
        <Typography className={classes.paragraph}>{textData}</Typography>
      </div>
    );
  }

  return (
    <div className={clsx(classes.root, className)}>
      {documentToReactComponents(isNil(textData) ? { raw: null } : textData, options)}
    </div>
  );
}

RichText.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  textData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

RichText.defaultProps = {
  classes: {},
  className: null,
  textData: null,
};

export default withStyles(styles)(RichText);
