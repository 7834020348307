/* eslint-disable react/prop-types */
import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import Divider from '@mui/material/Divider';

import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';

const inlineStyles = theme => ({
  button: {
    width: '100%',
    marginTop: '18px',
  },
  redButton: {
    width: '80%',
  },
  expansionPanel: {
    width: '100%',
  },
  type: {
    fontWeight: 'bold',
  },
  price: {
    fontWeight: 'bold',
    fontSize: 16,
    color: theme.palette.primary.main,
  },
  priceContainer: {
    textAlign: 'center',
    width: '80%',
    paddingBottom: theme.spacing(1),
  },
  pricePrimary: {
    fontWeight: 'bold',
    fontSize: 25,
    color: theme.palette.primary.main,
  },
  priceLabel: {
    fontWeight: 'bold',
    fontSize: 16,
    color: theme.palette.primary.main,
    lineHeight: 1,
    paddingBottom: 6,
  },
  description: {},
  arrow: {
    color: theme.palette.primary.main,
  },
});

/**
 * SubService
 * Use to print out buttons that contain subservice information such as "Silmänpohjankuvaus"
 * @param {Object} classes     Classes from JSS inlineStyles
 * @param {Object} content     Content object (headline, caption, prices)
 */

const SubService = ({ classes, content, lang, history, t }) => {
  const { headline, caption, prices, urlFragment } = content;

  const primaryPrice = prices.campaignPrice ? prices.campaignPrice : prices.normalPrice;
  const secondaryPrice = prices.campaignPrice ? prices.normalPrice : '';

  const handleClick = () => {
    history.push(`${lang}/${urlFragment}`);
  };

  return (
    <Grid item container>
      <Divider style={{ backgroundColor: 'rgb(210,216,219)', width: '100%' }} />
      <ButtonBase disableRipple className={classes.button} onClick={handleClick}>
        <Grid direction="row" spacing={0} container alignItems="center" wrap="nowrap">
          <Grid item xs={11} md={9}>
            <Grid direction="column" spacing={0} container align="left">
              <Grid item>
                <Typography variant="h3" className={classes.type} gutterBottom>
                  {headline}
                </Typography>
              </Grid>

              <Grid item sx={{ display: { md: 'none', xs: 'block' } }}>
                <Typography variant="body1" className={classes.price}>
                  {prices.priceLabel} {primaryPrice}
                </Typography>
                {secondaryPrice && (
                  <Typography variant="body2" gutterBottom>
                    ({t('views.landing.priceNormally')} {secondaryPrice})
                  </Typography>
                )}
              </Grid>
              <Grid item>
                <Typography variant="body2" className={classes.description}>
                  {caption}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} md={3} align="right">
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              <div className={classes.priceContainer}>
                {prices.priceLabel && (
                  <Typography variant="body1" className={classes.priceLabel}>
                    {prices.priceLabel}
                  </Typography>
                )}
                <Typography variant="body1" className={classes.pricePrimary}>
                  {primaryPrice}
                </Typography>
                {secondaryPrice && (
                  <Typography variant="body2" className={classes.priceSecondary} gutterBottom>
                    ({t('views.landing.priceNormally')} {secondaryPrice})
                  </Typography>
                )}
              </div>
            </Box>
            <Box sx={{ display: { md: 'none', xs: 'block' } }}>
              <Icon fontSize="large" className={classes.arrow}>
                arrow_forward
              </Icon>
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              <Button disableRipple color="secondary" variant="contained" className={classes.redButton} component={Box}>
                <strong>{t('views.availableTime.choose')}</strong>
              </Button>
            </Box>
          </Grid>
        </Grid>
      </ButtonBase>
    </Grid>
  );
};

export default withStyles(inlineStyles)(withTranslation('translations')(SubService));
