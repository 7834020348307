import '../../../assets/fonts/instrumentarium/fonts.css';

const brandColor = '#EC3225';
const white = '#FFFFFF';

export const colors = {
  black: 'rgb(0, 0, 0)',
  white: 'rgb(255, 255, 255)',
  palette: {
    primary: {
      light: '#757ce8',
      main: brandColor,
      dark: '#666',
      contrastText: white,
    },
    secondary: {
      light: white,
      main: '#000',
      dark: brandColor,
      contrastText: white,
    },
    background: {
      paper: white,
      dark: 'rgb(17,17,17)',
    },
    fontColor: {
      body: 'rgb(0, 0, 0)',
    },
  },
  button: {
    contained: {
      backgroundColor: brandColor,
      color: white,
      hover: {
        backgroundColor: '#fe2213',
        borderColor: '#fe2213',
        color: white,
      },
    },
    outlined: {
      hover: {
        backgroundColor: '#fde5e3',
        color: brandColor,
      },
    },
    selected: {
      backgroundColor: '#fe2213',
      color: white,
      hover: {
        backgroundColor: '#fde5e3',
        color: 'inherit',
      },
    },
  },
};

const primaryFontFamily = 'Instrumentarium, sans-serif';

export const typography = {
  fontFamilyBody: primaryFontFamily,
  fontFamilyTitle: primaryFontFamily,
  desktop: {
    fontSizeBody1: '18px',
    fontSizeBody2: '16px',
  },
  mobile: {
    fontSizeBody1: '16px',
    fontSizeBody2: '14px',
  },
};

export const components = {
  footer: {
    backgroundColor: colors.palette.background.dark,
  },
};
