import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';

import availabilityReducer from './availabilityReducer';
import contentfulReducer from './contentfulReducer';
import geolocationReducer from './geolocationReducer';
import appointmentReducer from './appointmentReducer';
import trackingReducer from './trackingReducer';

export default combineReducers({
  form: reduxFormReducer,
  availability: availabilityReducer,
  contentful: contentfulReducer,
  geolocation: geolocationReducer,
  appointment: appointmentReducer,
  tracking: trackingReducer,
});
