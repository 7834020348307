const settings = {
  locales: [
    // First locale is treated as default
    {
      languageCode: 'fi',
      localeLong: 'Finnish',
      gatCode: 'fi',
    },
    {
      languageCode: 'en',
      localeLong: 'English',
      gatCode: 'en',
    },
    {
      languageCode: 'sv',
      localeLong: 'Swedish',
      gatCode: 'sv',
    },
  ],
  showOpticians: true,
  showMyAppointments: true,
  phone: {
    countryCode: '+358',
    numberOfDigits: 9,
  },
};

export default settings;
