/* eslint-disable no-inner-declarations */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Helmet } from 'react-helmet';

const CookieConsent = () => {
  const id = process.env.REACT_APP_COOKIE_CONSENT_ID;

  if (id) {
    return (
      <Helmet>
        <script
          id="Cookiebot"
          src="https://consent.cookiebot.com/uc.js"
          data-cbid={id}
          data-blockingmode="auto"
          type="text/javascript"
        />
        <script data-cookieconsent="ignore">
          {(function (s, l, i) {
            if (typeof window !== 'undefined' && typeof document !== 'undefined') {
              window.dataLayer = window.dataLayer || [];
              function gtag() {
                // eslint-disable-next-line prefer-rest-params
                window.dataLayer.push(arguments);
              }
              gtag('consent', 'default', {
                ad_personalization: 'denied',
                ad_storage: 'denied',
                ad_user_data: 'denied',
                analytics_storage: 'denied',
                functionality_storage: 'denied',
                personalization_storage: 'denied',
                security_storage: 'granted',
                wait_for_update: 500,
              });
              gtag('set', 'ads_data_redaction', true);
              gtag('set', 'url_passthrough', false);
            }
          })('script', 'dataLayer')}
        </script>
        <script id="CookieDeclaration" src={`https://consent.cookiebot.com/${id}/cd.js`} type="text/javascript" async />
      </Helmet>
    );
  }

  return null;
};

CookieConsent.propTypes = {};

CookieConsent.defaultProps = {};

export default CookieConsent;
