/* eslint-disable react/prop-types */
import React from 'react';
import get from 'lodash/get';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import * as moment from 'moment';

import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import Price from './Price';
import { settings } from '../../sites';

const inlineStyles = theme => ({
  selectedServiceBox: {
    backgroundColor: 'rgb(242,247,250)',
    padding: theme.spacing(2.5, 2),
    marginBottom: 24,
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4.5, 2.5),
    },
  },
  bigger: {
    fontSize: 18,
  },
  biggerWrapper: {
    marginBottom: 20,
  },
  bookingReference: {
    color: theme.palette.primary.dark,
  },
  button: {
    color: theme.palette.primary.main,
    backgroundColor: '#fff',
    border: `1px solid ${theme.palette.primary.main}`,
    marginTop: 20,
  },
  smaller: {
    marginBottom: 3,
  },
  price: {
    marginTop: 20,
  },
});

/**
 * SelectedServiceBox
 * Use to show information of selected service
 * @param {Object} classes     Classes from JSS inlineStyles
 * @param {Object} content     Content object (headline, caption, price,
 * date, time, person, address, phone, email, location)
 */

const SelectedServiceBox = ({ classes, content, bookingReference, t }) => {
  const {
    headline,
    timeSpan,
    price,
    date,
    time,
    person,
    address,
    phone,
    email,
    dateBigger,
    timeBigger,
    description,
    endTime,
    timeSlot,
    storeName,
  } = content;

  const startTimeFormatted = moment(timeSlot, 'YYYY-MM-DDTHH:mm');
  const endTimeFormatted = moment(endTime, 'YYYY-MM-DDTHH:mm');
  const appointmentDuration = moment.duration(endTimeFormatted.diff(startTimeFormatted)).asMinutes();

  return (
    <div className={classes.selectedServiceBox}>
      {bookingReference && (
        <Grid container direction="row" spacing={0}>
          <Grid item xs={12} align="left">
            <Typography variant="h4">{t('views.thankYou.bookingInfoTitle')}:</Typography>
          </Grid>
        </Grid>
      )}
      <Grid container direction="row" spacing={0}>
        <Grid item xs={12} align="left">
          <Typography className={classes.headline} variant="h5">
            {headline.toUpperCase()}
          </Typography>
        </Grid>
      </Grid>

      {get(settings, 'showMyAppointments') && bookingReference && (
        <Box mb={2}>
          <Typography className={`${classes.bigger} ${classes.bookingReference}`} variant="body1">
            <strong>
              {t('views.thankYou.bookingReferenceId')}: {bookingReference}
            </strong>
          </Typography>
        </Box>
      )}

      {(dateBigger || timeBigger) && (
        <div className={classes.biggerWrapper}>
          {dateBigger && (
            <Typography className={classes.bigger} variant="body1">
              {dateBigger}
            </Typography>
          )}
          {timeBigger && (
            <Typography className={classes.bigger} variant="body1">
              {t('dateTime.clock')} {timeBigger} ({t('views.appointmentDetails.duration')} {appointmentDuration}{' '}
              {t('views.appointmentDetails.minutes')})
            </Typography>
          )}
        </div>
      )}

      {date && (
        <Typography className={classes.smaller} variant="body1">
          {date}
        </Typography>
      )}

      {description && (
        <Typography className={classes.smaller} variant="body1">
          {description}
        </Typography>
      )}

      {time && (
        <Typography className={classes.smaller} variant="body1">
          {time}
        </Typography>
      )}

      {!time && (
        <Typography className={classes.smaller} variant="body1">
          {timeSpan}{' '}
        </Typography>
      )}

      {storeName && (
        <Typography className={classes.smaller} variant="body2">
          <strong>{storeName}</strong>
        </Typography>
      )}

      {address && (
        <Typography className={classes.smaller} variant="body2">
          {address}
        </Typography>
      )}

      {get(settings, 'showOpticians') && person && (
        <Typography className={classes.smaller} variant="body2">
          <strong>{person}</strong>
        </Typography>
      )}

      {phone && (
        <Typography className={classes.smaller} variant="body1">
          <Link href={`tel:${phone}`} style={{ color: '#000' }} variant="body1">
            {phone}
          </Link>
        </Typography>
      )}

      {email && (
        <Typography className={classes.smaller} variant="body1">
          <Link href={`mailto:${email}`} style={{ color: '#000' }} variant="body1">
            {email}
          </Link>
        </Typography>
      )}

      <div className={classes.price}>
        <Price prices={price} />
      </div>
    </div>
  );
};

export default withStyles(inlineStyles)(withTranslation('translations')(SelectedServiceBox));
