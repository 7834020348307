import * as R from 'ramda';

import { TRACK_SAVE_URL_FRAGMENTS, CLEAR_DATA_TRACKING } from '../actions/trackingActions';

const initialState = {
  service: '',
  city: '',
  store: '',
};

// eslint-disable-next-line default-param-last
export default function trackingReducer(state = initialState, action) {
  switch (action.type) {
    case TRACK_SAVE_URL_FRAGMENTS:
      return {
        ...state,
        service: R.path(['values', 'service'], action),
        city: R.path(['values', 'city'], action),
        store: R.path(['values', 'store'], action),
      };
    case CLEAR_DATA_TRACKING:
      return {
        ...state,
        initialState,
      };
    default:
      return state;
  }
}
