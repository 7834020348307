import { isEqual } from 'lodash';

import { siteIds } from '../constants';

import InstrumentariumEESettings from './instrumentarium-ee/settings';
import InstrumentariumFISettings from './instrumentarium/settings';
import KeopsSettings from './keops/settings';
import NissenSettings from './nissen/settings';

export const siteId = process.env.REACT_APP_BRAND;

export const settings = (() => {
  if (isEqual(siteId, siteIds.InstrumentariumEE)) {
    return InstrumentariumEESettings;
  }
  if (isEqual(siteId, siteIds.InstrumentariumFI)) {
    return InstrumentariumFISettings;
  }
  if (isEqual(siteId, siteIds.Keops)) {
    return KeopsSettings;
  }
  if (isEqual(siteId, siteIds.Nissen)) {
    return NissenSettings;
  }
  return {};
})();
