import { siteId } from '../sites';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getPageTitle(pages) {
  const subPages = Array.isArray(pages) ? pages.join(' - ') : pages;
  return `${subPages} | ${capitalizeFirstLetter(siteId)}`;
}
