const brandColor = '#00AE91';
const white = '#FFFFFF';

export const colors = {
  black: 'rgb(0, 0, 0)',
  white: 'rgb(255, 255, 255)',
  palette: {
    primary: {
      light: '#3a9f86',
      main: '#00AE91',
      dark: '#000',
      contrastText: white,
    },
    secondary: {
      light: white,
      main: '#000',
      dark: '#EC3225',
      contrastText: white,
    },
    background: {
      paper: white,
      dark: 'rgb(17,17,17)',
    },
    fontColor: {
      body: 'rgb(0, 0, 0)',
    },
  },
  button: {
    contained: {
      backgroundColor: brandColor,
      color: white,
      hover: {
        backgroundColor: white,
        borderColor: brandColor,
        color: brandColor,
      },
    },
    outlined: {
      hover: {
        backgroundColor: brandColor,
        color: white,
      },
    },
    selected: {
      backgroundColor: brandColor,
      color: 'inherit',
      hover: {
        backgroundColor: brandColor,
        color: 'inherit',
      },
    },
  },
};

const primaryFontFamily = 'Source Sans Pro, sans-serif';
const secondaryFontFamily = 'Libre Caslon Text, serif';

export const typography = {
  fontFamilyBody: primaryFontFamily,
  fontFamilyTitle: secondaryFontFamily,
  desktop: {
    fontSizeBody1: '16px',
    fontSizeBody2: '15px',
  },
  mobile: {
    fontSizeBody1: '15px',
    fontSizeBody2: '14px',
  },
};

export const components = {
  footer: {
    backgroundColor: colors.palette.background.dark,
  },
};
