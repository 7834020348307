/* eslint-disable react/prop-types */
import React from 'react';
import { withStyles } from '@mui/styles';

const inlineStyles = () => ({
  logo: {
    maxHeight: 30,
    maxWidth: 185,
  },
});

const Logo = ({ classes, logoImage }) => <img src={logoImage} alt="" className={classes.logo} />;

export default withStyles(inlineStyles)(Logo);
