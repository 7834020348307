/* eslint-disable react/prop-types */
import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';
import Logo from '../subcomponents/Logo';
import RichText from '../../utils/RichText';

const inlineStyles = theme => ({
  footer: {
    backgroundColor: theme.footer.backgroundColor,
    color: '#fff',
    padding: '20px',
    marginTop: theme.spacing(5),
    textAlign: 'center',
  },
  contentWrapper: {
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  divider: {
    backgroundColor: 'rgb(86,99,110)',
  },
  footerPhoneContainer: {
    color: '#fff',
    marginBottom: '20px',
  },
  footerPhone: {
    fontSize: 24,
    fontWeight: 'bold',
    lineHeight: '26px',
  },
  footerSecondary: {
    color: '#fff',
    opacity: 0.8,
    fontSize: 13,
    marginBottom: '40px',

    '& p': {
      lineHeight: '1.2rem',
      marginBottom: 0,
      minHeight: '1rem',
    },

    '& a': {
      color: '#fff',
      display: 'inline-block',
      padding: '11px 0',
      textDecoration: 'underline',

      '&:hover': {
        opacity: 0.7,
      },
    },
  },
  logo: {
    padding: '25px 16px 40px',
  },
  logoWrapper: {
    textAlign: 'left',
  },
});

/**
 * Footer
 * Use to display the footer element
 */

const Footer = ({ classes, brandSettings, localeLong }) => {
  const footer = R.path(['footer', 'fields'], brandSettings);
  const footerMainContent = R.path([`footerMainContentIn${localeLong}`], footer);
  const footerSecondaryContent = R.path([`footerSecondaryContentIn${localeLong}`], footer);
  const phoneNumber = footer.appointmentPhoneNumber;
  const trimmedPhoneNumber = phoneNumber ? phoneNumber.replace(/\s/g, '') : '';

  return (
    <footer className={classes.footer}>
      {footer && (
        <Grid container direction="column" alignItems="center" spacing={2}>
          <Grid item className={classes.contentWrapper}>
            {footerMainContent && <Typography className={classes.footerHeadline}>{footerMainContent}</Typography>}
            {phoneNumber && (
              <Button
                disableRipple
                className={classes.footerPhoneContainer}
                startIcon={<Icon>phone</Icon>}
                href={`tel:${trimmedPhoneNumber}`}>
                <Typography className={classes.footerPhone}>{phoneNumber}</Typography>
              </Button>
            )}
            {footerSecondaryContent && (
              <RichText textData={footerSecondaryContent} className={classes.footerSecondary} />
            )}
          </Grid>
        </Grid>
      )}
      <Grid container direction="column">
        <Grid item xs={12}>
          <Divider variant="middle" className={classes.divider} />
        </Grid>
      </Grid>
      <Grid container direction="column" alignItems="flex-start">
        <Grid item>
          <div className={classes.logo}>
            <Logo logoImage={brandSettings.logo.fields.file.url} />
          </div>
        </Grid>
      </Grid>
    </footer>
  );
};

const mapStateToProps = state => ({
  localeLong: R.path(['contentful', 'localeLong'], state),
  brandSettings: R.path(['contentful', 'layout'], state),
});

export default withStyles(inlineStyles)(withTranslation('translations')(connect(mapStateToProps)(Footer)));
