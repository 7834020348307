import isEqual from 'lodash/isEqual';
import { createTheme } from '@mui/material/styles';

import { siteIds } from '../../constants';
import { siteId } from '../../sites';

import * as instrumentariumBrandStyles from './instrumentarium/brandStyles';
import * as nissenBrandStyles from './nissen/brandStyles';
import * as keopsBrandStyles from './keops/brandStyles';

let brandStyles = {};

if (isEqual(siteId, siteIds.Nissen)) {
  brandStyles = nissenBrandStyles;
} else if (isEqual(siteId, siteIds.Keops)) {
  brandStyles = keopsBrandStyles;
} else {
  brandStyles = instrumentariumBrandStyles;
}

const baseTheme = createTheme();
const theme = createTheme({
  ...baseTheme,
  typography: {
    fontFamily: brandStyles.typography.fontFamilyBody,
    fontSize: 12,
    em: {
      fontFamily: brandStyles.typography.fontFamilyBody,
      fontWeight: 'bold',
    },
    strong: {
      fontFamily: brandStyles.typography.fontFamilyBody,
      fontWeight: 'bold',
    },
  },
  palette: {
    primary: {
      light: brandStyles.colors.palette.primary.light,
      main: brandStyles.colors.palette.primary.main,
      dark: brandStyles.colors.palette.primary.dark,
      contrastText: brandStyles.colors.palette.primary.contrastText,
    },
    secondary: {
      light: brandStyles.colors.palette.secondary.light,
      main: brandStyles.colors.palette.secondary.main,
      dark: brandStyles.colors.palette.secondary.dark,
      contrastText: brandStyles.colors.palette.secondary.contrastText,
    },
    background: {
      paper: brandStyles.colors.palette.background.paper,
    },
    status: {
      error: 'rgb(254, 34, 19)',
    },
  },
  footer: {
    backgroundColor: brandStyles.components.footer.backgroundColor,
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          boxShadow: 'none',
          borderBottom: '1px solid rgba(205, 205, 205, 1)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          fontSize: 14,
          fontFamily: brandStyles.typography.fontFamilyBody,
          fontWeight: 'bold',
          padding: '10px 16px 6px 16px',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        label: {
          alignItems: 'initial !important',
        },
        outlined: {
          border: `1px solid ${brandStyles.colors.palette.primary.main} !important`,
          color: brandStyles.colors.palette.primary.main,
          padding: '9px 15px 5px 15px',
          '&:hover': {
            backgroundColor: brandStyles.colors.button.outlined.hover.backgroundColor,
            color: brandStyles.colors.button.outlined.hover.color,
          },
        },
        outlinedSecondary: {
          '&:hover': {
            backgroundColor: `${brandStyles.colors.button.outlined.hover.backgroundColor} !important`,
          },
        },
        containedSecondary: {
          backgroundColor: brandStyles.colors.button.contained.backgroundColor,
          border: `1px solid ${brandStyles.colors.button.contained.backgroundColor}`,
          color: brandStyles.colors.button.contained.color,
          '&:hover': {
            backgroundColor: `${brandStyles.colors.button.contained.hover.backgroundColor} !important`,
            borderColor: brandStyles.colors.button.contained.hover.borderColor,
            color: brandStyles.colors.button.contained.hover.color,
          },
        },
      },
    },
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          margin: 0,
        },
      },
    },
    MuiDayCalendar: {
      styleOverrides: {
        weekNumber: {
          fontSize: '10px !important',
        },
      },
    },
    MuiExpansionPanelSummary: {
      styleOverrides: {
        content: {
          margin: '30px 0 !important',
        },
        root: {
          padding: '0 15px 0 15px',
          [baseTheme.breakpoints.up('md')]: {
            padding: '0 30px 0 30px',
          },
        },
      },
    },

    MuiExpansionPanelDetails: {
      styleOverrides: {
        root: {
          padding: '0 15px 15px 15px',
          [baseTheme.breakpoints.up('md')]: {
            padding: '0 30px 30px 30px',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 18,
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        label: {
          fontSize: '1rem',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontSize: 35,
          color: brandStyles.colors.palette.primary.main,
          fontFamily: brandStyles.typography.fontFamilyTitle,
          fontWeight: 'bold',
          lineHeight: '32px',
          paddingTop: '30px',
          paddingBottom: '30px',
          textAlign: 'left',
          [baseTheme.breakpoints.up('md')]: {
            fontSize: 40,
            lineHeight: '64px',
          },
        },
        h2: {
          fontSize: 24,
          fontFamily: brandStyles.typography.fontFamilyTitle,
          fontWeight: 'bold',
          lineHeight: '18px',
          [baseTheme.breakpoints.up('md')]: {
            fontSize: 30,
            lineHeight: '36px',
          },
        },
        h3: {
          fontSize: 18,
          fontFamily: brandStyles.typography.fontFamilyTitle,
          fontWeight: 'bold',
          lineHeight: '24px',
          [baseTheme.breakpoints.up('md')]: {
            fontSize: 26,
            lineHeight: '40px',
          },
        },
        h4: {
          fontSize: 20,
          fontFamily: brandStyles.typography.fontFamilyTitle,
          fontWeight: 'bold',
          lineHeight: '24px',
          marginBottom: 20,
          [baseTheme.breakpoints.up('md')]: {
            fontSize: 24,
            lineHeight: '32px',
          },
        },
        h5: {
          fontSize: 16,
          fontFamily: brandStyles.typography.fontFamilyTitle,
          fontWeight: 'bold',
          lineHeight: '24px',
          marginBottom: 16,
          [baseTheme.breakpoints.up('md')]: {
            fontSize: 18,
            lineHeight: '28px',
          },
        },
        h6: {
          fontSize: 14,
          fontFamily: brandStyles.typography.fontFamilyTitle,
          fontWeight: 'bold',
          lineHeight: '18px',
          [baseTheme.breakpoints.up('md')]: {
            fontSize: 18,
            lineHeight: '24px',
          },
        },
        body1: {
          fontSize: brandStyles.typography.mobile.fontSizeBody1,
          width: '100%',
          lineHeight: '22px',
          [baseTheme.breakpoints.up('md')]: {
            fontSize: brandStyles.typography.desktop.fontSizeBody1,
            lineHeight: '27px',
          },
        },
        body2: {
          fontSize: brandStyles.typography.mobile.fontSizeBody2,
          width: '100%',
          lineHeight: '20px',
          [baseTheme.breakpoints.up('md')]: {
            fontSize: brandStyles.typography.desktop.fontSizeBody2,
            lineHeight: '24px',
          },
        },
      },
    },
    MuiPickersCalendar: {
      styleOverrides: {
        transitionContainer: {
          marginBottom: 12,
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          color: '#000',
          fontSize: 16,
          paddingLeft: '2px',
          paddingTop: '4px',
          '&.Mui-selected': {
            background: 'none',
            border: '3px solid #43aa4c',
            color: 'inherit',

            '&:focus': {
              backgroundColor: '#43aa4c',
            },
          },
        },
        today: {
          fontWeight: 'bold',
          '&:not(.Mui-selected)': {
            border: 'none',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: brandStyles.colors.palette.background.paper,
          fontSize: 18,
        },
        inputMultiline: {
          padding: '0px !important',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {},
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent !important',
          borderRadius: 0,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: 14,
          marginLeft: '0px !important',
          lineHeight: '18px',
          color: '#000 !important',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: brandStyles.typography.mobile.fontSizeBody2,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: brandStyles.colors.palette.fontColor.body,
          fontSize: brandStyles.typography.desktop.fontSizeBody1,
          lineHeight: 'initial !important',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          [baseTheme.breakpoints.up('lg')]: {
            alignSelf: 'center',
          },
        },
        regular: {
          [baseTheme.breakpoints.up('md')]: {
            minHeight: '96px !important',
          },
          [baseTheme.breakpoints.up('lg')]: {
            minHeight: '96px !important',
            width: '100%',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: 'rgba(0,0,0,0.23) !important',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        button: {
          '&:hover': {
            backgroundColor: brandStyles.colors.button.outlined.hover.backgroundColor,
          },
          '&.Mui-selected': {
            backgroundColor: brandStyles.colors.button.selected.backgroundColor,
            color: brandStyles.colors.button.selected.color,

            '&:hover': {
              backgroundColor: brandStyles.colors.button.selected.hover.backgroundColor,
              color: brandStyles.colors.button.selected.hover.color,
            },
          },
        },
      },
    },
    MuiPickersBasePicker: {
      styleOverrides: {
        container: {
          alignItems: 'center',
        },
        pickerView: {
          overflow: 'auto',
        },
      },
    },
  },
});

export default theme;
