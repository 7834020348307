import get from 'lodash/get';
import { settings } from '../sites';

export function getLanguageCodeArr() {
  return get(settings, 'locales', []).map(locale => get(locale, 'languageCode'));
}

export function getDefaultLanguageCode() {
  return get(settings, ['locales', 0, 'languageCode']);
}

export function getDefaultLocaleLong() {
  return get(settings, ['locales', 0, 'localeLong']);
}

export function getDefaultGatCode() {
  return get(settings, ['locales', 0, 'gatCode']);
}

export function getLocaleLong(languageCode) {
  const locales = get(settings, 'locales', []);

  // eslint-disable-next-line no-restricted-syntax
  for (const locale of locales) {
    if (get(locale, 'languageCode') === languageCode) {
      return get(locale, 'localeLong');
    }
  }
  return getDefaultLocaleLong();
}
export function getLanguageGatCode(languageCode) {
  const locales = get(settings, 'locales', []);

  // eslint-disable-next-line no-restricted-syntax
  for (const locale of locales) {
    if (get(locale, 'languageCode') === languageCode) {
      return get(locale, 'gatCode');
    }
  }
  return getDefaultGatCode();
}
