import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';

import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import theme from './components/themes/theme';
import * as serviceWorker from './serviceWorker';
// eslint-disable-next-line import/no-named-as-default
import App from './App';

import { store, persistor } from './store/store';

import './i18n';

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </StyledEngineProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
