/* eslint-disable react/prop-types */
import React from 'react';
import get from 'lodash/get';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';

import * as R from 'ramda';

import { connect } from 'react-redux';

import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';

import * as moment from 'moment';
import { selectTimeSlot } from '../../store/actions/availabilityActions';
import { settings } from '../../sites';
import { getLanguageGatCode } from '../../utils/localeUtils';

const inlineStyles = theme => ({
  button: {
    width: '100%',
    paddingTop: 20,
    paddingBottom: 20,
  },
  expansionPanel: {
    width: '100%',
  },
  arrow: {
    color: theme.palette.primary.main,
  },
  StartTime: {
    fontWeight: 'bold',
    fontSize: 16,
    [theme.breakpoints.up('md')]: {
      fontSize: 24,
    },
  },
  divider: {
    width: '100%',
  },
  price: {
    fontWeight: 'bold',
    textAlign: 'right',
  },
  organisationName: {
    margin: '15px 0 0',
  },
});

/**
 * SubService
 * Use to print out buttons that contain subservice information such as "Silmänpohjankuvaus"
 * @param {Object} classes     Classes from JSS inlineStyles
 * @param {Object} content     Content object (headline, caption, price)
 */

const AvailableTime = ({
  classes,
  content,
  // eslint-disable-next-line no-shadow
  selectTimeSlot,
  selectedOptician,
  selectedOpticianName,
  appointmentType,
  t,
  stores,
  storeId,
  day,
  lang,
  showOnlyStartTime,
}) => {
  const { startTime, endTime, price } = content;

  const storeDetails = R.find(R.propEq('id', storeId))(stores);

  const startTimeParsed = moment(startTime, 'YYYY-MM-DDTHH:mm');
  const endTimeParsed = moment(endTime, 'YYYY-MM-DDTHH:mm');

  const languageGatCode = getLanguageGatCode(lang);

  return (
    <Grid container>
      <ButtonBase
        disableRipple
        className={classes.button}
        onClick={() =>
          selectTimeSlot({
            timeSlot: startTimeParsed,
            endTime: endTimeParsed,
            day,
            timeSlotFormatted: startTimeParsed.format('HH:mm'),
            storeAddress: `${storeDetails.attributes.address.line1}, ${storeDetails.attributes.address.city}`,
            selectedStoreId: storeId,
            selectedOptician,
            selectedOpticianName,
            appointmentType,
            storeName: R.path(['attributes', 'name', languageGatCode], storeDetails),
          })
        }>
        <Grid direction="row" spacing={0} container alignItems="center">
          <Grid item xs={10} sm={8} align="left">
            <Grid direction="column" spacing={0} container>
              <Grid item>
                <Typography variant="body1" className={classes.StartTime}>
                  {showOnlyStartTime
                    ? `${startTimeParsed.format('HH:mm')}`
                    : `${startTimeParsed.format('HH:mm')}-${endTimeParsed.format('HH:mm')}`}
                </Typography>
                {showOnlyStartTime && (
                  <Typography variant="body1">{t('views.selectService.reserve-enough-time')}</Typography>
                )}
              </Grid>
              <Grid item>
                <Typography variant="h5" className={classes.organisationName}>
                  {R.path(['attributes', 'name', languageGatCode], storeDetails)}
                </Typography>
                <Typography variant="body1" className={classes.organisationAddress}>
                  {`${R.path(['attributes', 'address', 'line1'], storeDetails)}, ${R.path(
                    ['attributes', 'address', 'city'],
                    storeDetails,
                  )}`}
                </Typography>
                {get(settings, 'showOpticians') && (
                  <Typography variant="body1">
                    <strong>{selectedOpticianName}</strong>
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} sm={2} align="right">
            <Typography variant="body1" color="primary" className={classes.price}>
              {price && price}
            </Typography>
          </Grid>
          <Grid item xs={1} sm={2} align="right">
            <Box sx={{ display: { sm: 'none', xs: 'block' } }}>
              <Icon fontSize="large" className={classes.arrow}>
                arrow_forward
              </Icon>
            </Box>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              <Button disableRipple color="secondary" variant="contained" component={Box}>
                <strong>{t('views.availableTime.choose')}</strong>
              </Button>
            </Box>
          </Grid>
        </Grid>
      </ButtonBase>
      <Divider className={classes.divider} />
    </Grid>
  );
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  selectTimeSlot: values => dispatch(selectTimeSlot(values, R.path(['history'], ownProps), R.path(['lang'], ownProps))),
  lang: R.path(['lang'], ownProps),
});

export default withStyles(inlineStyles)(
  withTranslation('translations')(connect(null, mapDispatchToProps)(AvailableTime)),
);
